import * as React from "react";
import { PageProps } from "gatsby";
import Layout from "../../components/layout";
import Container from "../../components/ui-library/container/container";
import { AskAboutQuery_IntentCategory } from "../../../graphql-types";
import PageContainer from "../../components/ui-library/page-container/page-container";
import PageHeading from "../../components/ui-library/page-heading/page-heading";
import SEO from "../../components/seo/seo";
import { LogoBunny } from "../../components/site/header/header";
import Row from "../../components/ui-library/column-system/row";
import Column from "../../components/ui-library/column-system/column";
import { EventCategory } from "../../utils/analytics";
import Section from "../../components/ui-library/section/section";
import QuestionLink from "../../components/ui-library/question-link/question-link";
import { Topic } from "../../types/topics";
import { mapTopicUrlSegment } from "../../utils/map-topics";

interface CategoryData {
  category: AskAboutQuery_IntentCategory;
  intents: {
    alias: string;
    displayName: string;
  }[];
}

type BlockchainCategoryPageProps = PageProps<null, CategoryData>;

const BlockchainCategoryPage: React.FC<BlockchainCategoryPageProps> = ({
  pageContext: { category, intents },
}) => {
  const url = `/${mapTopicUrlSegment(Topic.Blockchain)}/`;
  return (
    <Layout
      topic={Topic.Blockchain}
      description="digital economy"
      headerUrl={url}
      logoBunny={LogoBunny.Main}
    >
      <SEO
        title={category.name}
        description={`Blockchain topic: ${category.name}`}
        imageUrl={`/media/images/social-share/blockchain/category-${category.alias}.jpg`}
      />

      <PageContainer>
        <PageHeading backUrl={url}>{category.name}</PageHeading>

        <Container>
          <Section>
            <Row>
              {intents.map((i) => (
                <Column key={i.alias} spanLg={6} spanMd={6} spanSm={12}>
                  <QuestionLink
                    to={`/blockchain/ask/${i.alias}/`}
                    eventCategory={EventCategory.CategoryPage}
                  >
                    {i.displayName}
                  </QuestionLink>
                </Column>
              ))}
            </Row>
          </Section>
        </Container>
      </PageContainer>
    </Layout>
  );
};

export default BlockchainCategoryPage;
